exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-fundraising-design-samples-tsx": () => import("./../../../src/pages/fundraising-design-samples.tsx" /* webpackChunkName: "component---src-pages-fundraising-design-samples-tsx" */),
  "component---src-pages-get-started-index-tsx": () => import("./../../../src/pages/get-started/index.tsx" /* webpackChunkName: "component---src-pages-get-started-index-tsx" */),
  "component---src-pages-get-started-thank-you-tsx": () => import("./../../../src/pages/get-started/thank-you.tsx" /* webpackChunkName: "component---src-pages-get-started-thank-you-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-recent-stores-tsx": () => import("./../../../src/pages/recent-stores.tsx" /* webpackChunkName: "component---src-pages-recent-stores-tsx" */),
  "component---src-pages-referrals-index-tsx": () => import("./../../../src/pages/referrals/index.tsx" /* webpackChunkName: "component---src-pages-referrals-index-tsx" */),
  "component---src-pages-referrals-new-tsx": () => import("./../../../src/pages/referrals/new.tsx" /* webpackChunkName: "component---src-pages-referrals-new-tsx" */),
  "component---src-pages-referrals-terms-tsx": () => import("./../../../src/pages/referrals/terms.tsx" /* webpackChunkName: "component---src-pages-referrals-terms-tsx" */),
  "component---src-pages-referrals-thank-you-tsx": () => import("./../../../src/pages/referrals/thank-you.tsx" /* webpackChunkName: "component---src-pages-referrals-thank-you-tsx" */),
  "component---src-pages-return-policy-tsx": () => import("./../../../src/pages/return-policy.tsx" /* webpackChunkName: "component---src-pages-return-policy-tsx" */),
  "component---src-templates-design-sample-js": () => import("./../../../src/templates/design-sample.js" /* webpackChunkName: "component---src-templates-design-sample-js" */)
}

